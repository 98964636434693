var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "p-4 bg-light mb-4"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.input,
      expression: "input"
    }],
    staticClass: "form-control",
    domProps: {
      "value": _vm.input
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.input = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onClickLookup
    }
  }, [_c('i', {
    staticClass: "fa fa-search mr-1"
  }), _vm._v(" Lookup")])])]), _vm.isLoading ? _c('div', {
    staticClass: "my-3"
  }, [_c('Spinner')], 1) : _vm.hasSearched ? _c('div', [_vm.category === 'epb' ? _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(" " + _vm._s((_vm.epbCertificates.lookup || []).reduce(function (sum, address) {
    return sum + (address.certificates || []).length;
  }, 0)) + " certificates and reports have been found for " + _vm._s(_vm.lastSearch) + " under " + _vm._s(_vm.epbCertificates.lookup.length) + " addresses. Click the "), _vm._m(0), _vm._v(" button to associate a certificate or report with your asset. ")]) : _c('p', {
    staticClass: "mb-3"
  }, [_vm._v(" " + _vm._s(_vm.assetCertificates.length) + " certificates and reports have been found near " + _vm._s(_vm.lastSearch) + ". Click the "), _vm._m(1), _vm._v(" button to associate a certificate or report with your asset. ")]), _vm.assetCertificates.length > 0 ? _c('div', {
    staticClass: "input-group mb-4"
  }, [_vm._m(2), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "Search results..."
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.search = $event.target.value;
      }
    }
  })]) : _vm._e(), _vm.category === 'epb' ? _c('div', _vm._l(_vm.filteredResults, function (item) {
    return _c('div', {
      key: item.address,
      staticClass: "mb-5"
    }, [_c('h5', {
      staticClass: "mb-2"
    }, [_vm._v(_vm._s(item.address))]), _vm._l(item.certificates, function (cert) {
      return _c('div', {
        key: cert.id,
        staticClass: "block block-rounded mb-2"
      }, [_c('div', {
        staticClass: "block-content block-content-full"
      }, [_c('div', {
        staticClass: "row align-items-center"
      }, [_c('div', {
        staticClass: "col-2"
      }, [_c('strong', [_vm._v(_vm._s(cert.type))])]), _c('div', {
        staticClass: "col text-left"
      }, [_c('span', {
        staticClass: "mr-3"
      }, [_vm._v(_vm._s(cert.date))]), _c('span', {
        staticClass: "font-w600",
        class: {
          'text-danger': cert.status === 'EXPIRED',
          'text-success': cert.status !== 'EXPIRED'
        }
      }, [_vm._v(_vm._s(cert.status))])]), _c('div', {
        staticClass: "col-3 text-right"
      }, [_c('button', {
        staticClass: "btn btn-sm btn-alt-info mr-3",
        on: {
          "click": function click($event) {
            return _vm.onClickViewEpb(cert);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-search"
      }), _vm._v(" VIEW")]), _c('span', [_vm.adding[cert.id] ? _c('span', {
        staticClass: "font-w600 text-warning"
      }, [_c('i', {
        staticClass: "fa fa-spin fa-circle-notch"
      }), _vm._v(" ADDING...")]) : !_vm.existingIds.includes(cert.id) ? _c('button', {
        staticClass: "btn btn-sm btn-alt-success",
        on: {
          "click": function click($event) {
            return _vm.onClickAddEpb(cert);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-plus"
      }), _vm._v(" ADD ")]) : _c('span', {
        staticClass: "font-w600 text-muted"
      }, [_vm._v("ADDED")])])])]), _vm.expanded[cert.id] ? _c('div', {
        staticClass: "mt-3"
      }, [_vm.epbCertificates.details[cert.id] ? _c('CertificateLookupItem', {
        attrs: {
          "cert": _vm.epbCertificates.details[cert.id]
        }
      }) : _vm._e()], 1) : _vm._e()])]);
    })], 2);
  }), 0) : _vm._e(), _vm.category === 'breeam' ? _c('div', _vm._l(_vm.filteredResults, function (cert) {
    return _c('div', {
      key: cert.id,
      staticClass: "block block-rounded mb-2"
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [_c('div', {
      staticClass: "row align-items-center"
    }, [_c('div', {
      staticClass: "col-3"
    }, [_c('div', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(cert.certNo))]), _c('div', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(cert.certBody))])]), _c('div', {
      staticClass: "col text-left"
    }, [_c('div', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(cert.buildingName))]), _c('div', {
      staticClass: "font-size-sm"
    }, [_vm._v(" " + _vm._s(cert.scheme) + " ")]), _c('div', {
      staticClass: "font-size-sm"
    }, [_vm._v(" " + _vm._s(cert.standard) + " ")])]), _c('div', {
      staticClass: "col-2 text-right"
    }, [_c('div', {
      staticClass: "font-size-lg"
    }, [_vm._v(_vm._s(cert.score) + "%")])]), _c('div', {
      staticClass: "col-3 text-right"
    }, [_c('button', {
      staticClass: "btn btn-sm btn-alt-info mr-3",
      on: {
        "click": function click($event) {
          return _vm.onClickViewBreeam(cert);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-search"
    }), _vm._v(" VIEW")]), _c('span', [_vm.adding[cert.id] ? _c('span', {
      staticClass: "font-w600"
    }, [_c('i', {
      staticClass: "fa fa-spin fa-circle-notch"
    })]) : !_vm.existingIds.includes(cert.id) ? _c('button', {
      staticClass: "btn btn-sm btn-alt-success",
      on: {
        "click": function click($event) {
          return _vm.onClickAddBreeam(cert);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-plus"
    }), _vm._v(" ADD ")]) : _c('span', {
      staticClass: "font-w600 text-muted"
    }, [_vm._v("ADDED")])])])]), _vm.expanded[cert.id] ? _c('div', {
      staticClass: "mt-3"
    }, [_vm.epbCertificates.details[cert.id] ? _c('CertificateLookupItem', {
      attrs: {
        "cert": _vm.epbCertificates.details[cert.id]
      }
    }) : _vm._e()], 1) : _vm._e()])]);
  }), 0) : _vm._e(), _vm.category === 'wiredScore' ? _c('div', _vm._l(_vm.filteredResults, function (cert) {
    return _c('div', {
      key: cert.id,
      staticClass: "block block-rounded mb-2"
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [_c('div', {
      staticClass: "row align-items-center"
    }, [_c('div', {
      staticClass: "col-3"
    }, [_c('div', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(cert.name))]), _c('div', {
      staticClass: "text-muted font-size-sm"
    }, [_c('i', {
      staticClass: "fa fa-location-dot"
    }), _vm._v(" " + _vm._s((cert.distance * 0.621371).toFixed(2)) + " miles away")])]), _c('div', {
      staticClass: "col text-right"
    }, [_c('div', {
      staticClass: "font-size-lg"
    }, [_vm._v(_vm._s(cert.certification_level))])]), _c('div', {
      staticClass: "col-3 text-right"
    }, [_c('button', {
      staticClass: "btn btn-sm btn-alt-info mr-3",
      on: {
        "click": function click($event) {
          return _vm.onClickViewWiredScore(cert);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-search"
    }), _vm._v(" VIEW")]), _c('span', [_vm.adding[cert.id] ? _c('span', {
      staticClass: "font-w600"
    }, [_c('i', {
      staticClass: "fa fa-spin fa-circle-notch"
    })]) : !_vm.existingIds.includes(cert.id) ? _c('button', {
      staticClass: "btn btn-sm btn-alt-success",
      on: {
        "click": function click($event) {
          return _vm.onClickAddWiredScore(cert);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-plus"
    }), _vm._v(" ADD ")]) : _c('span', {
      staticClass: "font-w600 text-success"
    }, [_c('i', {
      staticClass: "fa fa-circle-check"
    }), _vm._v(" ADDED")])])])]), _vm.expanded[cert.id] ? _c('div', {
      staticClass: "mt-3"
    }, [_vm.epbCertificates.details[cert.id] ? _c('CertificateLookupItem', {
      attrs: {
        "cert": _vm.epbCertificates.details[cert.id]
      }
    }) : _vm._e()], 1) : _vm._e()])]);
  }), 0) : _vm._e()]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.typeOptions[_vm.category].hasMap,
      expression: "typeOptions[category].hasMap"
    }],
    ref: "map",
    style: {
      width: '100%',
      height: '400px'
    }
  })]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "font-w600"
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" ADD")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "font-w600"
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" ADD")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "input-group-text"
  }, [_c('i', {
    staticClass: "fa fa-search"
  })]);
}]

export { render, staticRenderFns }