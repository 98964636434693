var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-2"
  }, [_vm.cert.type === 'CEPC' ? _c('div', [_vm._v(_vm._s(_vm.cert.score) + _vm._s(_vm.cert.rating))]) : _vm.cert.type === 'DEC' ? _c('div', [_vm._v(_vm._s(_vm.cert.score) + _vm._s(_vm.cert.rating))]) : _vm.cert.type === 'AA-CERT' ? _c('div', [_vm._v(_vm._s(_vm.cert.score) + _vm._s(_vm.cert.rating))]) : _vm.cert.type === 'CEPC-RR' ? _c('div', [_c('strong', [_vm._v("EPC Recommendation Report")])]) : _vm.cert.type === 'DEC-RR' ? _c('div', [_vm._v(_vm._s(_vm.cert.score) + _vm._s(_vm.cert.rating))]) : _vm.cert.type === 'AA-REPORT' ? _c('div', [_vm._v(_vm._s(_vm.cert.score) + _vm._s(_vm.cert.rating))]) : _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("Certificate not supported.")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }