<template>
  <i class="fa" :class="icon" />
</template>
<script>
import { fileIcon } from '@/lib/helpers';

export default {
  name: 'FileIcon',
  props: {
    extension: {
      type: String,
      required: true
    }
  },
  computed: {
    icon() {
      return fileIcon(this.extension);
    }
  }
};
</script>
