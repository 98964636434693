<template>
  <div class="p-2">
    <div v-if="cert.type === 'CEPC'">{{ cert.score }}{{ cert.rating }}</div>
    <div v-else-if="cert.type === 'DEC'">{{ cert.score }}{{ cert.rating }}</div>
    <div v-else-if="cert.type === 'AA-CERT'">{{ cert.score }}{{ cert.rating }}</div>
    <div v-else-if="cert.type === 'CEPC-RR'">
      <strong>EPC Recommendation Report</strong>
    </div>
    <div v-else-if="cert.type === 'DEC-RR'">{{ cert.score }}{{ cert.rating }}</div>
    <div v-else-if="cert.type === 'AA-REPORT'">{{ cert.score }}{{ cert.rating }}</div>
    <div v-else class="alert alert-warning">Certificate not supported.</div>
  </div>
</template>
<script>
export default {
  name: 'CertificateLookupItem',
  props: {
    cert: {
      type: Object,
      required: true
    }
  }
};
</script>
