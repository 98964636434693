<template>
  <div class="d-flex justify-content-end">
    <div class="d-flex align-items-center">
      <div v-if="loading" class="mr-3">
        <i class="fa fa-spinner-third fa-lg fa-spin"></i>
      </div>
      <div v-if="summary" class="align-items-center font-w600 mr-3">Page {{ currentPage }} of {{ totalPages }}</div>
      <ul v-if="totalPages > -1" class="pagination mb-0">
        <li class="page-item" :class="{ disabled: loading }">
          <a class="page-link" href="#" aria-label="First" @click.prevent="onChangePage(1)"
            ><i class="fa fa-angle-double-left"></i><span aria-hidden="true"><span class="sr-only">First</span></span></a
          >
        </li>
        <li class="page-item" :class="{ disabled: loading || currentPage - 1 <= 0 }">
          <a class="page-link" href="#" aria-label="Prev" @click.prevent="onChangePage(currentPage - 1)"
            ><i class="fa fa-angle-left"></i><span aria-hidden="true"><span class="sr-only">Prev</span></span></a
          >
        </li>
        <li v-if="currentPage === totalPages && currentPage - 2 > 0" class="page-item" :class="{ disabled: loading }">
          <a class="page-link" href="#" @click.prevent="onChangePage(currentPage - 2)">{{ currentPage - 2 }}</a>
        </li>
        <li v-if="currentPage - 1 > 0" class="page-item" :class="{ disabled: loading }">
          <a class="page-link" href="#" @click.prevent="onChangePage(currentPage - 1)">{{ currentPage - 1 }}</a>
        </li>
        <li class="page-item active">
          <a class="page-link" href="#">{{ currentPage }}</a>
        </li>
        <li v-if="currentPage + 1 <= totalPages" class="page-item" :class="{ disabled: loading }">
          <a class="page-link" href="#" @click.prevent="onChangePage(currentPage + 1)">{{ currentPage + 1 }}</a>
        </li>
        <li v-if="currentPage === 1 && currentPage + 2 <= totalPages" class="page-item" :class="{ disabled: loading }">
          <a class="page-link" href="#" @click.prevent="onChangePage(currentPage + 2)">{{ currentPage + 2 }}</a>
        </li>
        <li class="page-item" :class="{ disabled: loading || currentPage + 1 > totalPages }">
          <a class="page-link" href="#" aria-label="Next" @click.prevent="onChangePage(currentPage + 1)"
            ><i class="fa fa-angle-right"></i><span aria-hidden="true"><span class="sr-only">Next</span></span></a
          >
        </li>
        <li class="page-item" :class="{ disabled: loading }">
          <a class="page-link" href="#" aria-label="Last" @click.prevent="onChangePage(totalPages)"
            ><i class="fa fa-angle-double-right"></i><span aria-hidden="true"><span class="sr-only">Last</span></span></a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    onClickPage: {
      type: Function,
      required: false,
      default: () => ({})
    },
    currentPage: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    summary: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChangePage(page) {
      this.onClickPage(page);
      this.$emit('change', page);
    }
  }
};
</script>
