<template>
  <div>
    <div class="p-4 bg-light mb-4">
      <div class="input-group">
        <input v-model="input" class="form-control" />
        <button class="btn btn-primary" @click="onClickLookup"><i class="fa fa-search mr-1"></i> Lookup</button>
      </div>
    </div>
    <div v-if="isLoading" class="my-3">
      <Spinner />
    </div>
    <div v-else-if="hasSearched">
      <p v-if="category === 'epb'" class="mb-3">
        {{ (epbCertificates.lookup || []).reduce((sum, address) => sum + (address.certificates || []).length, 0) }} certificates and reports have been
        found for {{ lastSearch }} under {{ epbCertificates.lookup.length }} addresses. Click the
        <span class="font-w600"><i class="fa fa-plus"></i> ADD</span> button to associate a certificate or report with your asset.
      </p>
      <p v-else class="mb-3">
        {{ assetCertificates.length }} certificates and reports have been found near {{ lastSearch }}. Click the
        <span class="font-w600"><i class="fa fa-plus"></i> ADD</span> button to associate a certificate or report with your asset.
      </p>

      <div v-if="assetCertificates.length > 0" class="input-group mb-4">
        <span class="input-group-text">
          <i class="fa fa-search"></i>
        </span>
        <input v-model="search" class="form-control" placeholder="Search results..." />
      </div>

      <div v-if="category === 'epb'">
        <div v-for="item in filteredResults" :key="item.address" class="mb-5">
          <h5 class="mb-2">{{ item.address }}</h5>

          <div v-for="cert in item.certificates" :key="cert.id" class="block block-rounded mb-2">
            <div class="block-content block-content-full">
              <div class="row align-items-center">
                <!-- <div class="col-1">
                <a href="#" @click.prevent="onClickCertificate(cert)">
                  <i
                    class="fa fa-lg fa-fw"
                    :class="{
                      'fa-spin fa-circle-notch': loadingAction.getEpbCertificate[cert.id]
                    }"
                /></a>
              </div> -->
                <div class="col-2">
                  <strong>{{ cert.type }}</strong>
                </div>
                <div class="col text-left">
                  <span class="mr-3">{{ cert.date }}</span>
                  <span class="font-w600" :class="{ 'text-danger': cert.status === 'EXPIRED', 'text-success': cert.status !== 'EXPIRED' }">{{
                    cert.status
                  }}</span>
                </div>
                <div class="col-3 text-right">
                  <button class="btn btn-sm btn-alt-info mr-3" @click="onClickViewEpb(cert)"><i class="fa fa-search"></i> VIEW</button>

                  <span>
                    <span v-if="adding[cert.id]" class="font-w600 text-warning"><i class="fa fa-spin fa-circle-notch"></i> ADDING...</span>
                    <button v-else-if="!existingIds.includes(cert.id)" class="btn btn-sm btn-alt-success" @click="onClickAddEpb(cert)">
                      <i class="fa fa-plus"></i> ADD
                    </button>
                    <span v-else class="font-w600 text-muted">ADDED</span>
                  </span>
                </div>
              </div>
              <div v-if="expanded[cert.id]" class="mt-3">
                <CertificateLookupItem v-if="epbCertificates.details[cert.id]" :cert="epbCertificates.details[cert.id]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="category === 'breeam'">
        <div v-for="cert in filteredResults" :key="cert.id" class="block block-rounded mb-2">
          <div class="block-content block-content-full">
            <div class="row align-items-center">
              <!-- <div class="col-1">
                <a href="#" @click.prevent="onClickCertificate(cert)">
                  <i
                    class="fa fa-lg fa-fw"
                    :class="{
                      'fa-spin fa-circle-notch': loadingAction.getEpbCertificate[cert.id]
                    }"
                /></a>
              </div> -->
              <div class="col-3">
                <div class="font-w600">{{ cert.certNo }}</div>
                <div class="text-muted">{{ cert.certBody }}</div>
              </div>
              <div class="col text-left">
                <div class="font-w600">{{ cert.buildingName }}</div>
                <div class="font-size-sm">
                  {{ cert.scheme }}
                </div>
                <div class="font-size-sm">
                  {{ cert.standard }}
                </div>
              </div>
              <div class="col-2 text-right">
                <div class="font-size-lg">{{ cert.score }}%</div>
              </div>
              <div class="col-3 text-right">
                <button class="btn btn-sm btn-alt-info mr-3" @click="onClickViewBreeam(cert)"><i class="fa fa-search"></i> VIEW</button>

                <span>
                  <span v-if="adding[cert.id]" class="font-w600"><i class="fa fa-spin fa-circle-notch"></i></span>
                  <button v-else-if="!existingIds.includes(cert.id)" class="btn btn-sm btn-alt-success" @click="onClickAddBreeam(cert)">
                    <i class="fa fa-plus"></i> ADD
                  </button>
                  <span v-else class="font-w600 text-muted">ADDED</span>
                </span>
              </div>
            </div>
            <div v-if="expanded[cert.id]" class="mt-3">
              <CertificateLookupItem v-if="epbCertificates.details[cert.id]" :cert="epbCertificates.details[cert.id]" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="category === 'wiredScore'">
        <div v-for="cert in filteredResults" :key="cert.id" class="block block-rounded mb-2">
          <div class="block-content block-content-full">
            <div class="row align-items-center">
              <!-- <div class="col-1">
                <a href="#" @click.prevent="onClickCertificate(cert)">
                  <i
                    class="fa fa-lg fa-fw"
                    :class="{
                      'fa-spin fa-circle-notch': loadingAction.getEpbCertificate[cert.id]
                    }"
                /></a>
              </div> -->

              <div class="col-3">
                <div class="font-w600">{{ cert.name }}</div>
                <div class="text-muted font-size-sm"><i class="fa fa-location-dot"></i> {{ (cert.distance * 0.621371).toFixed(2) }} miles away</div>
              </div>
              <div class="col text-right">
                <div class="font-size-lg">{{ cert.certification_level }}</div>
              </div>
              <div class="col-3 text-right">
                <button class="btn btn-sm btn-alt-info mr-3" @click="onClickViewWiredScore(cert)"><i class="fa fa-search"></i> VIEW</button>

                <span>
                  <span v-if="adding[cert.id]" class="font-w600"><i class="fa fa-spin fa-circle-notch"></i></span>
                  <button v-else-if="!existingIds.includes(cert.id)" class="btn btn-sm btn-alt-success" @click="onClickAddWiredScore(cert)">
                    <i class="fa fa-plus"></i> ADD
                  </button>
                  <span v-else class="font-w600 text-success"><i class="fa fa-circle-check"></i> ADDED</span>
                </span>
              </div>
            </div>
            <div v-if="expanded[cert.id]" class="mt-3">
              <CertificateLookupItem v-if="epbCertificates.details[cert.id]" :cert="epbCertificates.details[cert.id]" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="typeOptions[category].hasMap" ref="map" :style="{ width: '100%', height: '400px' }"></div>
  </div>
</template>
<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import CertificateLookupItem from './CertificateLookupItem';
import Spinner from './Spinner';

export default {
  name: 'CertificateLookup',
  components: {
    CertificateLookupItem,
    Spinner
  },
  props: {
    category: {
      type: String,
      required: true
    },
    asset: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      input: '',
      expanded: {},
      adding: {},
      hasSearched: false,
      lastSearch: '',
      search: '',
      typeOptions: {
        epb: {
          friendly: 'certificate',
          hasInput: true,
          searchKeys: ['address']
        },
        breeam: {
          friendly: 'BREEAM certificate',
          hasInput: true,
          searchKeys: ['certNo', 'buildingName']
        },
        wiredScore: {
          friendly: 'Wired Score certificate',
          hasInput: true,
          hasGeocode: true,
          searchKeys: ['name']
        }
      },
      googleMaps: {}
    };
  },
  computed: {
    ...mapGetters({
      epbCertificates: 'asset/epbCertificates',
      assetCertificates: 'asset/assetCertificates',
      loadingAction: 'asset/loadingAction'
    }),
    filteredResults() {
      let results = [];

      if (this.category === 'epb') {
        results = this.epbCertificates.lookup || [];
      } else {
        results = this.assetCertificates || [];
      }

      return results.filter(
        item =>
          !this.search || !this.options.searchKeys || this.options.searchKeys.some(key => item[key].toLowerCase().includes(this.search.toLowerCase()))
      );
    },
    isLoading() {
      return this.loadingAction.lookupEpbCertificates || this.loadingAction.lookupAssetCertificates;
    },
    existingIds() {
      return this.asset.documents.filter(cert => cert.certificate?.id).map(cert => cert.certificate.id);
    },
    options() {
      return this.typeOptions[this.category] || {};
    }
  },
  async mounted() {
    this.input = this.asset.address?.postCode || '';

    if (this.options.hasMap || this.options.hasGeocode) {
      this.googleMaps = await GoogleMapsApiLoader({
        apiKey: process.env.VUE_APP_GOOGLE_API_KEY
      });

      this.map = new this.googleMaps.maps.Map(this.$refs.map, {
        center: {
          lat: parseFloat(this.asset.address.latitude),
          lng: parseFloat(this.asset.address.longitude)
        },
        zoom: 14
      });
    }

    // if (options.hasMap) {
    // }

    if (this.input) {
      this.onClickLookup();
    }
  },
  beforeDestroy() {
    // this.resetEpbCertificates();
    // this.resetBreeamCertificates();
  },
  methods: {
    ...mapActions({
      lookupEpbCertificates: 'asset/lookupEpbCertificates',
      lookupAssetCertificates: 'asset/lookupAssetCertificates',
      getEpbCertificate: 'asset/getEpbCertificate',
      createDocument: 'asset/createDocument'
    }),
    ...mapMutations({
      resetEpbCertificates: 'asset/LOOKUP_EPB_CERTIFICATES_RESET'
    }),
    async onClickLookup() {
      this.hasSearched = false;
      this.lastSearch = this.input;

      if (this.category === 'epb' && this.input) {
        await this.lookupEpbCertificates({
          params: {
            postcode: this.input
          }
        });
      } else if (this.category === 'wiredScore' && this.input) {
        const latLng = await this.getLatLngFromPostcode(this.input);
        const [lat, lng] = [latLng.lat(), latLng.lng()];

        await this.lookupAssetCertificates({
          params: {
            type: 'wiredScore',
            northBound: (lat + 0.0025).toFixed(13),
            southBound: (lat - 0.0025).toFixed(13),
            eastBound: (lng + 0.005).toFixed(13),
            westBound: (lng - 0.005).toFixed(13),
            center: `${lat},${lng}`
          }
        });
      } else if (this.input) {
        await this.lookupAssetCertificates({
          params: {
            type: this.category,
            postcode: this.input
          }
        });
      }

      this.hasSearched = true;
    },
    async getLatLngFromPostcode(postcode) {
      const geocoder = new this.googleMaps.maps.Geocoder();

      const geocode = await geocoder.geocode({
        address: postcode
      });

      if (geocode.results.length === 0) return null;

      return geocode.results[0].geometry?.location;
    },
    async onClickAddEpb(cert) {
      if (this.loadingAction.getEpbCertificate[cert.id]) return false;

      this.$set(this.adding, cert.id, true);

      if (!this.epbCertificates.details[cert.id]) await this.getEpbCertificate({ id: cert.id });

      const detailedCert = this.epbCertificates.details[cert.id];

      const score = detailedCert.score ? `${detailedCert.score}${detailedCert.rating}` : null;
      const name = `${cert.type} - ${cert.date} - ${detailedCert.address}`;

      const document = {
        name,
        type: 'epb',
        score,
        providerId: cert.id,
        providerData: detailedCert,
        certificate: detailedCert,
        date: new Date()
      };

      await this.createDocument({ id: this.asset._id, data: document });

      this.$set(this.adding, cert.id, false);
    },
    async onClickAddBreeam(cert) {
      const name = `${cert.certNo} - ${cert.buildingName}`;

      this.$set(this.adding, cert.id, true);

      const document = {
        name,
        type: 'breeam',
        score: cert.score,
        certificate: cert,
        date: new Date()
      };

      await this.createDocument({ id: this.asset._id, data: document });

      this.$set(this.adding, cert.id, false);
    },
    async onClickAddWiredScore(cert) {
      this.$set(this.adding, cert.id, true);

      const document = {
        name: cert.name,
        type: 'wiredScore',
        score: cert.certification_level,
        certificate: cert,
        date: new Date()
      };

      await this.createDocument({ id: this.asset._id, data: document });

      this.$set(this.adding, cert.id, false);
    },
    onClickViewEpb(cert) {
      if (cert.url) {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = cert.url;
        link.click();
      }
    },
    onClickViewBreeam(cert) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = cert.url;
      link.click();
    },
    onClickViewWiredScore(cert) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = cert.url;
      link.click();
    }
  }
};
</script>
<style lang="scss" scoped>
.band-a-score-badge {
  background-color: #008054;
}
.band-b-score-badge {
  background-color: #19b459;
}
.band-c-score-badge {
  background-color: #8dce46;
}
.band-d-score-badge {
  background-color: #ffd500;
}
.band-e-score-badge {
  background-color: #fcaa65;
}
.band-f-score-badge {
  background-color: #ef8023;
}
.band-g-score-badge {
  background-color: #e9153b;
}
.EpcLookupForm-table {
  .custom-checkbox input {
    cursor: pointer;
  }
}
</style>
